import React, { ReactNode } from 'react';

import { Row } from 'components/row/Row';

import s from './BlockText.scss';

export enum TextColors {
  WHITE = 'white',
  BLACK = 'black',
}

interface BlockTextProps {
  heading: string;
  isThankYouPage: boolean;
  description: ReactNode;
  children: ReactNode;
  color: string;
}

export const BlockText = ({ heading, description,children, color, isThankYouPage }: BlockTextProps) => (
  <>
    <div className={s.block}>
      <Row>
        <div className={s.block__col}>
          <h3 className={s(s.block__heading, color, {thanks: isThankYouPage})}>{heading}</h3>
          <h4 className={s(s.block__description, color, {thanks: isThankYouPage})}>{description}</h4>
        </div>
        {children}
      </Row>
    </div>
  </>
);
