import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { Container } from '../components/container/Container';
import { BlockText, TextColors } from '../components/block-text/BlockText';
import { Button } from '../components/button/Button';
import { useAppDispatchContext, useAppStateContext } from '../context/appContext';
import SEO from '../components/seo/SEO';
import { AnimationWrapper } from '../components/animationWrapper/AnimationWrapper';

import s from '../components/thankYouPage/thankYouPage.scss';


export default () => {
  const intl = useIntl();

  const dispatch = useAppDispatchContext();
  const { cursorStyles } = useAppStateContext();

  const onCursor = cursorType => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  return (
    <>
      <SEO title="Thank You"/>
      <div className={s.thankYouPage}>
        <div className={s(s.thankYouPage__page)} onMouseOver={() => onCursor('whiteDefault')}
             onMouseLeave={() => onCursor()}>
          <div className={s.thankYouPage__container}>
            <Container>
              <BlockText
                heading={intl.formatMessage({id: "thankYouPageHeader"})}
                description={intl.formatMessage({id: "thankYouPageDescription"})}
                color={TextColors.WHITE}
                isThankYouPage
              />
              <div className={s.thankYouPage__action}>
                <AnimationWrapper
                  path={'/'}
                >
                  <Button thanksButton color={'white'}>
                    {intl.formatMessage({id: "thankYouPageButton"})}
                  </Button>
                </AnimationWrapper>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
